<template>
  <form ref="profile_form" @submit.prevent="handleSubmit">
    <base-input
      :label="`${$t('COMMON.ORGANIZATION')} (*)`"
      :placeholder="$t('COMMON.ORGANIZATION')"
      v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)"
    >
      <organization-selector
        :organization="unitOfMeasure.organization.id"
        :filterable="true"
        :showAll="false"
        @organizationChanged="
          (organizationId) => {
            unitOfMeasure.organization.id = organizationId;
            onFormChanged();
          }
        "
      />
    </base-input>
    <validation-error :errors="apiValidationErrors.organization" />

    <base-input
      :label="`${$t('COMMON.NAME')} (*)`"
      :placeholder="$t('COMMON.NAME')"
      v-model="unitOfMeasure.name"
      @change="onFormChanged()"
    />
    <validation-error :errors="apiValidationErrors.name" />

    <div class="my-4">
      <base-button
        type="button"
        class="btn btn-sm btn-primary"
        native-type="submit"
        :disabled="loading"
      >
        <i class="fas fa-spinner fa-spin" v-if="loading" />
        {{
          unitOfMeasure.id
            ? $t("PRODUCTS.EDIT_UNIT_OF_MEASURE")
            : $t("PRODUCTS.ADD_UNIT_OF_MEASURE")
        }}
      </base-button>
    </div>
  </form>
</template>
<script>
import { cloneDeep } from "lodash";
import { Option, Select } from "element-ui";
import formMixin from "@/mixins/form-mixin";
import ValidationError from "@/components/ValidationError.vue";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import models from "@/constants/models";
import OrganizationSelector from "@/components/OrganizationSelector.vue";

export default {
  layout: "DashboardLayout",

  name: "unit-of-measure-form",

  components: {
    BaseInput,
    ValidationError,
    OrganizationSelector,
    [Select.name]: Select,
    [Option.name]: Option,
  },

  mixins: [formMixin],

  props: ["unitOfMeasureData", "formErrors", "loading"],

  data() {
    let unitOfMeasureData = cloneDeep(this.unitOfMeasureData);
    unitOfMeasureData = this.$fillUserOrganizationData(unitOfMeasureData);
    return {
      unitOfMeasure: unitOfMeasureData,
      permissions: [],
      permissionsModel: {},
      models: models,
    };
  },

  created() {},

  methods: {
    async handleSubmit() {
      let unitOfMeasureData = cloneDeep(this.unitOfMeasure);
      unitOfMeasureData = this.$fillUserOrganizationData(unitOfMeasureData);
      this.$emit("unitOfMeasureSubmitted", unitOfMeasureData);
    },

    onFormChanged() {
      this.$emit("formChanged");
    },
  },

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
    unitOfMeasureData(unitOfMeasureData) {
      if (unitOfMeasureData) {
        this.unitOfMeasure = {
          ...this.unitOfMeasure,
          ...cloneDeep(unitOfMeasureData),
        };
      }
    },
  },
};
</script>
